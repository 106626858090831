export const MOCK_DONG_INFO = {
  dev: {
    url: 'https://custom.dev.hars.kr',
    userId: '5',
    campaignId: {
      timeSale: 1281,
      arPortal: 1280,
    },
    couponCode: '4',
    jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaXNTaWduVXAiOnRydWUsImV4cGlyZWRBdCI6IjIwMjQtMDgtMTlUMjM6NTk6NTkrMDk6MDAiLCJpYXQiOjE3MjA1NzY0NzgsImV4cCI6MTcyNDA3OTU5OX0.MhsBw76StXfiC5T_6MQjyJ4YAztVi88fmyZHvSEUYv0',
    storeUrl: 'https://store.dev.hyper-cloud.kr/poi/:storeId/coupon/use',
  },
  prod: {
    url: 'https://custom.hars.kr',
    userId: '113',
    campaignId: {
      timeSale: 55,
      arPortal: 54,
    },
    couponCode: '3',
    jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTEzLCJpc1NpZ25VcCI6dHJ1ZSwiZXhwaXJlZEF0IjoiMjAyNC0wOC0xOVQyMzo1OTo1OSswOTowMCIsImlhdCI6MTcyMDU3NjQ3OCwiZXhwIjoxNzI0MDc5NTk5fQ.I9zEwAGVBkym5JMSM2I8Aq99Gf7Sl13RiFpjo4DnP_k',
    storeUrl: 'https://store.hyper-cloud.kr/poi/:storeId/coupon/use',
  },
  demo: {
    url: 'https://custom.dev.hars.kr',
    userId: '5',
    campaignId: {
      timeSale: 1281,
      arPortal: 1313,
    },
    couponCode: '4',
    jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiaXNTaWduVXAiOnRydWUsImV4cGlyZWRBdCI6IjIwMjQtMDgtMTlUMjM6NTk6NTkrMDk6MDAiLCJpYXQiOjE3MjA1NzY0NzgsImV4cCI6MTcyNDA3OTU5OX0.MhsBw76StXfiC5T_6MQjyJ4YAztVi88fmyZHvSEUYv0',
    storeUrl: 'https://store.dev.hyper-cloud.kr/poi/:storeId/coupon/use',
  }
} as const;

export function getEnvInfo() {
  if (process.env.NODE_ENV === "development") {
    return MOCK_DONG_INFO.dev;
  } else if (process.env.NODE_ENV === "production") {
    return MOCK_DONG_INFO.prod;
  } else {
    return MOCK_DONG_INFO.demo;
  }
}
